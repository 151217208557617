import * as React from "react";

const CkeckMark = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <path fill="#E5F1FF" d="M14.17 28.32c7.826 0 14.17-6.34 14.17-14.16S21.996 0 14.17 0 0 6.34 0 14.16s6.344 14.16 14.17 14.16z" transform="translate(-151 -1325) translate(100 844) translate(51.141 481.03) translate(0 .94)"/>
                                <g stroke="#377DFF" stroke-dasharray="0 0" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                                    <path d="M14 0L4.375 10 0 5.455" transform="translate(-151 -1325) translate(100 844) translate(51.141 481.03) translate(0 .94) translate(7.28 9.97)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CkeckMark;