import * as React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PhoneNumberUtil } from "google-libphonenumber";
import _ from "lodash";

const newPhoneNumberUtils = new PhoneNumberUtil();
const usePhoneNumberUtils = ({ country }) => {
  const { t } = useTranslation();
  const placeholder = React.useMemo(() => {
    try {
      const exampleNumber = newPhoneNumberUtils.getExampleNumberForType(country.toUpperCase(), 1);
      const nationalNumber = exampleNumber.getNationalNumberOrDefault();
      const countryCodeSourceOrDefault = exampleNumber.getCountryCodeSourceOrDefault();

      return `${nationalNumber}`;
    } catch (error) {
      return "";
    }
  }, [country]);
  const formatInOriginalFormat = React.useCallback((number) => {
    try {
      const parsedNumber = newPhoneNumberUtils.parse(number, country.toUpperCase());

      return newPhoneNumberUtils.formatInOriginalFormat(parsedNumber);
    } catch (error) {
      return number;
    }
  }, [country]);
  const formatNumber = React.useCallback((number, format) => {
    try {
      if (_.isEmpty(number)) return "";
      const parsedNumber = newPhoneNumberUtils.parse(number, country.toUpperCase());
      const formattedPhone = newPhoneNumberUtils.format(parsedNumber, format).replaceAll(" ", "").replace("(", "").replace(")", "").replaceAll("-", "");

      // if (format === 2) {
      //   return formattedPhone.startsWith("0") ? formattedPhone : `0${formattedPhone}`;
      // }

      return formattedPhone;
    } catch (error) {
      return number;
    }
  }, [country]);
  const isPossibleNumberForTypeWithReason = React.useCallback((number) => {
    try {
      const parsedNumber = newPhoneNumberUtils.parse(number, country.toUpperCase());
      const validationResult = newPhoneNumberUtils.isPossibleNumberForTypeWithReason(parsedNumber, 1);

      return validationResult ? t(`phoneNumber.errors.${validationResult}`) : t("phoneNumber.errors.invalid");
    } catch (error) {
      return t("phoneNumber.errors.invalid");
    }
  }, [country, t]);
  const isValidNumberForRegion = React.useCallback((number) => {
    try {
      const parsedNumber = newPhoneNumberUtils.parse(number, country.toUpperCase());
      const isValidNumber = newPhoneNumberUtils.isValidNumberForRegion(parsedNumber, country.toUpperCase());

      if (isValidNumber) return undefined;

      return isPossibleNumberForTypeWithReason(number);
    } catch (error) {
      return t("phoneNumber.errors.invalid");
    }
  }, [country, isPossibleNumberForTypeWithReason, t]);
  const validator = React.useCallback((value) => {
    if (!value) return;
    const formattedValue = value?.replace("+", "");

    if (_.isEmpty(formattedValue)) return t("phoneNumber.errors.phoneRequired");

    return isValidNumberForRegion(value);
  }, [isValidNumberForRegion, t]);


  return {
    formatInOriginalFormat,
    isValidNumberForRegion,
    validator,
    placeholder,
    formatNumber,
    isPossibleNumberForTypeWithReason
  };
};

export default usePhoneNumberUtils;