import React from "react";

const SplitSection = ({
  id,
  primarySlot,
  secondarySlot,
  reverseOrder,
  imageFirst,
}) => (
  <section id={id} className="py-10">
    <div className="lg:mx-56 md:mx-16 mx-10 items-center flex flex-col lg:flex-row ">
      <div className={imageFirst ? "lg:w-3/5" : "lg:w-3/5"}>{primarySlot}</div>
      <div
        className={`mt-10 lg:mt-0 w-full ${
          imageFirst ? "lg:w-3/5" : "lg:w-6/12"
        } ${reverseOrder && `order-last lg:order-first`}`}
      >
        {secondarySlot}
      </div>
    </div>
  </section>
);

export default SplitSection;
