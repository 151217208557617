import * as React from "react";
import { Trans } from 'gatsby-plugin-react-i18next';
import Modal from "react-modal";

// icons
import YouTubeIcon from "@mui/icons-material/YouTube";

const customStyles = {
    content: {
        backgroundColor: "rgba(0, 0, 0, .6)",
        width: "100vw",
        height: "100vh",
        zIndex: 99999999
    },
};

Modal.setAppElement("#___gatsby");

const YoutubeVideo = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const openModal = React.useCallback(() => setIsModalOpen(true), []);
    const onClick = React.useCallback((event) => {
      event.stopPropagation();
      openModal();
    }, []);
    const closeModal = React.useCallback(() => setIsModalOpen(false), []);

    return ( 
        <div>
            <a
                href="#"
                className="greyText inline-block mx-auto mt-4"
                onClick={onClick}
            >
                <div className="flex-1 flex flex-row">
                    <p className="pr-2"><Trans>learnMore</Trans></p>
                    <YouTubeIcon htmlColor="#616161" />
                </div>
            </a>
            <Modal
                isOpen={isModalOpen}
                contentLabel="onRequestClose Example"
                onRequestClose={closeModal}
                className="fixed top-0 left-0 right-0 bottom-0 zInd"
                overlayClassName="Overlay"
                style={customStyles}
            >
                <button
                    onClick={closeModal}
                    className="absolute text-white"
                    style={{
                        top: 20,
                        right: 40,
                    }}
                >
                    <Trans i18nKey="close" />
                </button>
                <iframe className="sm:w-full sm:px-8 lg:w-2/3 mx-auto lg:px-0 center-element youtube-video"
                        src="https://www.youtube-nocookie.com/embed/ozoimfSED_A?si=UMMQahhb-1K8RXOa"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
            </Modal>
        </div>
    );
};

export default React.memo(YoutubeVideo);