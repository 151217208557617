import axios from "axios";
import config from '../config';

export const DEFAULT_COUNTRY = {
    code: "MA",
    name: "morocco"
}
const fetchCountriesInfo = async () => {
    try {
        const res = await axios.get(`${config.GATSBY_API_URL}/country/supported`);
        const { data } = res;
        if (data) {
            const { countries, defaultCountry } = data;
            return { supportedCountries: countries, defaultCountry };
        }
        return { defaultCountry: DEFAULT_COUNTRY, supportedCountries: [DEFAULT_COUNTRY] };
    } catch (e) {
        return { defaultCountry: DEFAULT_COUNTRY, supportedCountries: [DEFAULT_COUNTRY] };
    }
}
export default fetchCountriesInfo;