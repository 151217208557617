import React from "react";

class Accordion extends React.Component {
  render() {
    return (
      <div className="accordion py-10">
        <hr className="accordion-hr" />
        {this.props.hiddenTexts.map((hiddenText) => (
          <AccordionItem key={hiddenText.label} hiddenText={hiddenText} />
        ))}
      </div>
    );
  }
}

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
    this.state = {
      visibility: false,
    };
  }

  handleToggleVisibility() {

    this.setState((prevState) => {
      return {
        visibility: !prevState.visibility,
      };
    });
  }
  render() {
    const activeStatus = this.state.visibility ? "active" : "";
    const { value, label } = this.props.hiddenText;

    return (
      <div>
        <button
          className="accordion__button relative"
          onClick={this.handleToggleVisibility}
        >
          <span className="text-xl lg:text-2xl text-left">
            {label}
          </span>
          <div>
            <span
              className={
                this.state.visibility
                  ? "icon fas fa-chevron-up"
                  : "icon fas fa-chevron-down"
              }
            />
          </div>
        </button>
        <p className={`accordion__content ${activeStatus}`}>
          {
            Array.isArray(value) ? (
              <ul>
                { value.map(({ className, text }, index) => <li className={className} key={index}>{text}</li>)}
              </ul>
            ) : (
              <div
                  dangerouslySetInnerHTML={{ __html: value }}
              />
            )
          }
        </p>
        <hr className="accordion-hr" />
      </div>
    );
  }
}

export default Accordion;
