import * as React from "react";
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

// custom components
import CarouselWrapper from "./CarouselWrapper";
import SplitSection from "../components/SplitSection";

// images
import {
    clientAppSlidesFR,
    driverAppSlidesFR,
    restaurantAppSlidesFR,
    clientAppSlidesEN,
    driverAppSlidesEN,
    restaurantAppSlidesEN
} from "../assets/images";

const Features = () => {
    const { language } = useI18next();

    return (
        <>
            <SplitSection
                id="features"
                primarySlot={
                <div className="lg:pr-8 xl:pr-12 pl-5" id="client">
                    <h3 className="text-3xl font-semibold leading-tight">
                    <Trans i18nKey="features.ordering.title" />
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                    <ul className="list-disc">
                        <li><Trans i18nKey="features.ordering.feature1" /></li>
                        <li><Trans i18nKey="features.ordering.feature2" /></li>
                        <li><Trans i18nKey="features.ordering.feature3" /></li>
                        <li><Trans i18nKey="features.ordering.feature4" /></li>
                        <li><Trans i18nKey="features.ordering.feature5" /></li>
                        <li><Trans i18nKey="features.ordering.feature6" /></li>
                        <li><Trans i18nKey="features.ordering.feature7" /></li>
                    </ul>
                    </p>
                </div>
                }
                secondarySlot={
                <div>
                    <CarouselWrapper slides={language === "fr" ? clientAppSlidesFR : clientAppSlidesEN} />
                </div>
                }
            />
            <SplitSection
                id="driver"
                reverseOrder
                primarySlot={
                <div className="lg:pl-8 xl:pl-12">
                    <h3 className="text-3xl font-semibold leading-tight">
                    <Trans i18nKey="features.delivery.title" />
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                    <ul className="list-disc">
                        <li><Trans i18nKey="features.delivery.feature1" /></li>
                        <li><Trans i18nKey="features.delivery.feature2" /></li>
                        <li><Trans i18nKey="features.delivery.feature3" /></li>
                        <li><Trans i18nKey="features.delivery.feature4" /></li>
                        <li><Trans i18nKey="features.delivery.feature5" /></li>
                        <li><Trans i18nKey="features.delivery.feature6" /></li>
                        <li><Trans i18nKey="features.delivery.feature7" /></li>
                    </ul>
                    </p>
                </div>
                }
                secondarySlot={
                <div>
                    <CarouselWrapper slides={language === "fr" ? driverAppSlidesFR : driverAppSlidesEN} />
                </div>
                }
            />
            <SplitSection
                id="restaurant"
                primarySlot={
                <div className="">
                    <h3 className="text-3xl font-semibold leading-tight">
                    <Trans i18nKey="features.360_management.title" />
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                    <ul className="list-disc">
                        <li><Trans i18nKey="features.360_management.feature1" /></li>
                        <li><Trans i18nKey="features.360_management.feature2" /></li>
                        <li><Trans i18nKey="features.360_management.feature3" /></li>
                        <li><Trans i18nKey="features.360_management.feature4" /></li>
                        <li><Trans i18nKey="features.360_management.feature5" /></li>
                        <li><Trans i18nKey="features.360_management.feature6" /></li>
                        <li><Trans i18nKey="features.360_management.feature7" /></li>
                        <li><Trans i18nKey="features.360_management.feature8" /></li>
                        <li><Trans i18nKey="features.360_management.feature9" /></li>
                        <li><Trans i18nKey="features.360_management.feature10" /></li>
                    </ul>
                    </p>
                </div>
                }
                secondarySlot={
                <div>
                    <CarouselWrapper medium slides={language === "fr" ? restaurantAppSlidesFR : restaurantAppSlidesEN} />
                </div>
                }
            />
        </>
    )
}

export default Features;