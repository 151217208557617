import React from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
//import axios from "axios";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "./Button";
import axios from "axios";
import config from "../config";

// icons
import CheckMark from "./icons/CheckMark";
import Delete from "./icons/Delete";

// styles
import "./pricingTable.css";

const PRICING_PACKAGES = [
  "default",
  "small",
  "medium",
  "premium"
];
const DEFAULT_FEATURES = [
  "website",
  "clickCollect",
  "discounts",
  "analytics",
  "customersData",
];
const SUPPORTED_FEATURES = [
  "marketing-notification",
  "delivery",
  "multi-store",
  "multi-manager",
  "online-payment",
  "custom-domains",
  "mobile-app",
  "multi-brands",
  "deliverect",
  "marketplace",
];
const ROWS = [
  "setupFee",
  "maxOrders",
  "extraOrderFee",
  "menuEntry"
];

const Pricing = ({ handlePricingPackage, country }) => {
  const { t } = useTranslation();
  const [duration, setDuration] = React.useState('annually');
  const [pricingPackages, setPricingPackages] = React.useState([]);
  const onClick = (duration) => () => {
    setDuration(duration)
  };

  React.useEffect(() => {
    axios.get(`${config.GATSBY_API_URL}/pricingPackages/scope/${country}`).then((response) => {
      const {
        data
      } = response;

      setPricingPackages(data);
    });
  }, [country]);
  const getPricingPackageValue = React.useCallback((pricingPackageType, field) => {
    const pricingPackage = pricingPackages.find(({ type }) => pricingPackageType === type);
    return pricingPackage ? pricingPackage[field] : undefined;
  }, [pricingPackages]);

  return (
    <div className="pb-6">
      {/*<div>
        <ul className="switch">
          <li className="tab">
            <span id="label-monthly"><Trans i18nKey="pricing.monthlyPayment" /></span>
          </li>
          <li className={`layerbg ${duration === "monthly" ? "disabled" : ""}`}>
            <button type="button" onClick={onClick("monthly")} className={`btn left ${duration === "monthly" ? "active" : ""}`} aria-labelledby="label-monthly" />
            <button type="button" onClick={onClick("annually")} className={`btn right ${duration === "annually" ? "active" : ""}`} aria-labelledby="label-annually" />
          </li>
          <li className="tab">
            <span id="label-annually"><Trans i18nKey="pricing.annualPayment" /></span>
            <span className="save bg-primary-200">{t("pricing.yearlyDiscount")}</span>
          </li>
        </ul>
      </div>*/}

      <div>
        <table className="pricingTable">
          <thead>
            <tr>
              <th>{t("pricing.packageName")}</th>
              <th>{t("pricing.free")}</th>
              <th>{t("pricing.default")}</th>
              <th>{t("pricing.small")}</th>
              <th>{t("pricing.medium")}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-primary-200 section-price">
              <td className="section text-center" colSpan={5}>
                {t("pricing.price")}
              </td>
            </tr>
            {
              duration === "monthly" ? (
                <tr>
                  <td>{t("pricing.monthlyFixedFee")}</td>
                  {PRICING_PACKAGES.map((pricingPackage) => {
                    const price = getPricingPackageValue(pricingPackage, "monthlyFixedFee") || 0;
                    const currencyCode = getPricingPackageValue(pricingPackage, "currency");
                    const currency= currencyCode==='MAD' || !currencyCode ? `${t("moroccanCurrency")} ${t("withVat")}` : currencyCode;
                    return (
                      <td key={pricingPackage}>
                        <b className="break-all">
                          {
                            `${price} ${currency}`
                          }
                        </b>
                        <sup className="sup font-semibold">{t("pricing.storeMonth")}</sup>
                      </td>
                    );
                  })}
                </tr>
              ) : (
                <tr>
                  <td>{t("pricing.monthlyFixedFeeWithAnnualDiscount")}</td>
                  {PRICING_PACKAGES.map((pricingPackage) => {
                    const priceMonthly = getPricingPackageValue(pricingPackage, "monthlyFixedFee") || 0;
                    const price = getPricingPackageValue(pricingPackage, "monthlyFixedFeeWithAnnualDiscount") || 0;
                    const currencyCode = getPricingPackageValue(pricingPackage, "currency");
                    const currency= currencyCode==='MAD' || !currencyCode ? `${t("moroccanCurrency")} ${t("withVat")}` : currencyCode;

                    return (
                      <td key={pricingPackage}>
                        <b className="break-all">
                          {
                            `${price} ${currency}`
                          }
                        </b>
                        {/*
                          priceMonthly ? (
                            <div className="block small">
                              <span className="line-through break-all red">{`${priceMonthly} ${currency}`}&nbsp;</span>
                            </div>
                          ) : null
                        */}
                        <sup className="sup font-semibold">{t("pricing.storeMonth")}</sup>
                        <small className="billed-annually">{`${price * 12} ${currency}`} {t("pricing.billedAnnually")}</small>
                      </td>
                    )
                  })}
                </tr>
              )
            }

            {
              ROWS.map((row) => (
                <tr>
                  <td>{t(`pricing.${row}`)}</td>
                  {
                    PRICING_PACKAGES.map((pricingPackage) => {
                      const value = getPricingPackageValue(pricingPackage, row) === undefined ? "N/A" : getPricingPackageValue(pricingPackage, row);
                      const currencyCode = getPricingPackageValue(pricingPackage, "currency");
                      const currency= currencyCode==='MAD' || !currencyCode ? `${t("moroccanCurrency")} ${t("withVat")}` : currencyCode;

                      if (row === "maxOrders") return (
                        <td className="font-semibold">
                          {
                            `${value} ${value !== "N/A" ? t("pricing.orders") : ""}`
                          }
                        </td>
                      );

                      if (row === "menuEntry") return (
                          <td className="font-semibold">
                            { t("pricing.menuEntryPrice")}
                          </td>
                      );

                      return (
                        <td className="font-semibold">
                          {
                            `${value} ${value === "N/A" ? "" : currency}${row === "setupFee" ? t("pricing.perStore") : t("pricing.perExtraOrder")}`
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              ))
            }
            <tr className="bg-primary-200 section-commitment">
              <td className="section text-center" colSpan={5}>
                {t("pricing.commitment")}
              </td>
            </tr>
            <tr>
              <td>{t("pricing.commitment")}</td>
              <td className="font-semibold">
                <Trans i18nKey="pricing.commitments.free" />
              </td>
              <td className="font-semibold">
                <Trans i18nKey="pricing.commitments.starter" />
                {/*<span className="billed-annually">
                  <Trans i18nKey="pricing.commitments.requiredFees" />
                </span>*/}
              </td>
              <td className="font-semibold">
                <Trans i18nKey="pricing.commitments.pro" />
                {/*<span className="billed-annually">
                  <Trans i18nKey="pricing.commitments.requiredFees" />
                </span>*/}
              </td>
              <td className="font-semibold">
                <Trans i18nKey="pricing.commitments.premium" />
                {/*<span className="billed-annually">
                  <Trans i18nKey="pricing.commitments.requiredFees" />
                </span>*/}
              </td>
            </tr>
            {/*<tr>
              <td>{t("pricing.trial")}</td>
              <td className="font-semibold">{t("pricing.trials.free")}</td>
              <td className="font-semibold">{t("pricing.trials.starter")}</td>
              <td className="font-semibold">{t("pricing.trials.pro")}</td>
              <td className="font-semibold">{t("pricing.trials.premium")}</td>
            </tr>*/}
            <tr className="bg-primary-200 section-features">
              <td className="section text-center" colSpan={5}>
                {t("pricing.features")}
              </td>
            </tr>
            {
              DEFAULT_FEATURES.map((defaultFeature) => (
                  <tr>
                    <td>{t(`pricing.${defaultFeature}`)}</td>
                    {PRICING_PACKAGES.map(() => <td><CheckMark /></td>)}
                  </tr>
              ))
            }
            {
              SUPPORTED_FEATURES.map((supportedFeature) => (
                <tr>
                  <td>{t(`pricing.${supportedFeature}`)}</td>
                  {PRICING_PACKAGES.map((pricingPackage) => <td>{(getPricingPackageValue(pricingPackage, "supportedFeatures") || []).includes(supportedFeature) ? <CheckMark /> : <Delete />}</td>)}
                </tr>
              ))
            }
            <tr>
              <td></td>
              {
                PRICING_PACKAGES.map((pricingPackage) => (
                  <td>
                    <AnchorLink onClick={() => handlePricingPackage(pricingPackage)} className="lg:px-4 px-0 pt-4 getStarted block" href="#free-trial">
                      <Button className="start-btn text-sm py-3 px-4 bg-primary text-white" size={false}>
                        <span>
                          {t('start')}
                        </span>
                      </Button>
                    </AnchorLink>
                  </td>
                ))
              }
            </tr>
          </tbody>
        </table>
      </div>
      <div className="banner bg-primary mt-6 flex items-center justify-between py-3 px-6 rounded-lg">
        <div className="first">
          <p className="text-white">
            <span className="font-semibold">
              {t("pricing.youHaveMany")}
            </span>
            &nbsp;
            {t("pricing.contactUseToGet")}
          </p>
        </div>
        <div className="second">
          <AnchorLink href="#contact">
            <Button className="font-semibold text-sm py-3 px-4 bg-white text-primary" size={false}>
              <span>
                {t('contactUs')}
              </span>
            </Button>
          </AnchorLink>
        </div>
      </div>
    </div>
  )
};

export default Pricing;