export default [
    require('./1.webp'),
    require('./2.webp'),
    require('./3.webp'),
    require('./4.webp'),
    require('./5.webp'),
    require('./6.webp'),
    require('./7.webp'),
    require('./8.webp'),
];
