import * as React from "react";
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

// custom components
import Card from "./Card";
import AnchorLink from "react-anchor-link-smooth-scroll";

const OurSolutions = ({sectionId}) => {
    const { t } = useTranslation();
    const cards = t("ourSolutions.cards", { returnObjects: true });

    return (
        <section id={sectionId} className="py-4 grey-background">
            <div className=" md:mx-24 mx-2 sm:mx-10 text-center">
                <h2 className="text-3xl lg:text-5xl font-semibold text-center w-full">
                    <Trans i18nKey="ourSolutions.mainTitle" />
                </h2>
                <div className="justify-center mt-12">
                    <div className="flex flex-col flex-wrap sm:flex-row justify-center">
                        {
                            cards.map(({ title, description, icon, link }) => (
                                <div className="px-3 md:w-1/3">
                                    <Card className="mb-8 card px-4">
                                        <div className="flex-1 flex flex-row justify-center items-center">
                                            {/* you can find the icons name here https://fonts.google.com/icons */}
                                            <i className="material-icons card-icon">{icon}</i>
                                            <p className="font-semibold text-xl">{title}</p> 
                                        </div>
                                        <p className="mt-4">{description}</p>
                                        {/*
                                            link ? (
                                                <AnchorLink offset={() => 64} className='blueLink' href={link}>
                                                    <Trans>learnMore</Trans>
                                                </AnchorLink>
                                            ) : null
                                        */}
                                    </Card>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default React.memo(OurSolutions);