import React from "react";
import { Trans } from 'gatsby-plugin-react-i18next';
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Modal from "react-modal";
import "./style.css";

Modal.setAppElement("#___gatsby");

class CarouselWrapper extends React.Component {
  constructor(props) {
    super(props);
    const slides = props.slides ? props.slides : [];
    const m_slides = props.slides ? props.slides : [];

    this.state = {
      value: 0,
      slides: slides.map((url) => <img alt="Screenshot" src={url} />),

      m_value: 0,
      m_slides: m_slides.map((url) => <img alt="Screenshot" src={url} />),

      showModal: false,
    };

    this.onchange = this.onchange.bind(this);
    this.m_onchange = this.m_onchange.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  //TODO use webp format

  onchange(value) {
    this.setState({ value });
  }

  m_onchange(m_value) {
    this.setState({ m_value });
  }

  renderFullScreenModal() {
    if (!this.state.showModal) return null;
    return (
      <Modal
        isOpen={true}
        contentLabel="onRequestClose Example"
        onRequestClose={this.handleCloseModal}
        className="fixed top-0 left-0 right-0 bottom-0 zInd"
        overlayClassName="Overlay"
      >
        <div
          className="w-full h-full flex justify-center items-center relative"
          style={{
            background: "rgb(0 0 0 / 60%)",
          }}
        >
          <button
            onClick={this.handleCloseModal}
            className="absolute text-white"
            style={{
              top: 20,
              right: 20,
            }}
          >
            <Trans i18nKey="close" />
          </button>
          <button
            onClick={() => {
              this.state.m_value > 0
                ? this.setState({
                    m_value: this.state.m_value - 1,
                  })
                : this.setState({
                    m_value: 0,
                  });
            }}
            className="text-white bg-primary absolute z-50 px-3 py-1"
            style={{
              left: 10,
              borderRadius: 4,
            }}
          >
            {"<"}
          </button>
          <button
            onClick={() => {
              this.state.m_value < this.state.slides.length
                ? this.setState({
                    m_value: this.state.m_value + 1,
                  })
                : this.setState({
                    m_value: this.state.slides.length,
                  });
            }}
            className="text-white bg-primary absolute z-50 px-3 py-1"
            style={{
              right: 10,
              borderRadius: 4,
            }}
          >
            {">"}
          </button>
          <div
            className={
              this.props.bigger
                ? "sm:w-4/5 w-11/12"
                : this.props.medium
                ? "sm:w-3/5  w-11/12"
                : "sm:w-6/12 w-full"
            }
          >
            <Carousel
              plugins={["fastSwipe"]}
              value={this.state.m_value}
              slides={this.state.m_slides}
              onChange={this.m_onchange}
            />
            <Dots
              value={this.state.m_value}
              onChange={this.m_onchange}
              number={this.state.m_slides.length}
            />
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div className="relative">
        <Carousel
          plugins={["fastSwipe"]}
          value={this.state.value}
          slides={this.state.slides}
          onChange={this.onchange}
        />

        <Dots
          className={`overflow-x-scroll pb-1 hideScroll transform ${
            this.props.bigger
              ? "sm:translate-y-6 translate-y-4"
              : this.props.medium
              ? "sm:-translate-y-10 -translate-y-3"
              : " sm:-translate-y-6 -translate-y-2"
          }`}
          value={this.state.value}
          onChange={this.onchange}
          number={this.state.slides.length}
        />

        <div
          className={`flex justify-center blueLink transform ${
            this.props.bigger
              ? "sm:translate-y-10 translate-y-5"
              : this.props.medium
              ? "sm:-translate-y-6 -translate-y-3"
              : "sm:-translate-y-4 -translate-y-1"
          } `}
        >
          <button onClick={this.handleOpenModal} className="focus:outline-none">
            <Trans i18nKey="fullScreen" />
          </button>
        </div>
        {this.renderFullScreenModal()}
      </div>
    );
  }
}
export default CarouselWrapper;
