import React from 'react';

const SelectInput = ({
    className = '',
    value = '',
    onChange = () => {},
    options = [],
    label = '',
}) => {
    return (
        <div className={`sm:w-1/2 px-3 mb-3 ${className}`}>
          { label ? <label for="selectInput" className="text-gray-600 font-medium mb-2 block">{label}</label> : null }
          <select
            name="selectInput"
            className="border-2 rounded px-3 py-1 w-full input focus:outline-none"
            onChange={onChange}
            value={value}
          >
            {
              options.map(({ value, name }) => (<option value={value}>{name}</option>))
            }
          </select>
        </div>
    )
};

export default SelectInput;
