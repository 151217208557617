import React, {useState} from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const TextArea = ({
        placeholder = '',
        className = '',
        value = '',
        isRequired = true,
        invalidMessagePath = 'errors.requiredField',
        onChange = () => {},
        forceValidation = false
    }) => {
    const { t } = useTranslation();
    const [touched, setTouched] = useState(false);
    const isInvalid = isRequired && !value && (touched || forceValidation);
    return (

        <div className={`sm:w-full px-3 ${className}`}>
            <textarea
                className={`border-2 rounded px-3 py-1 w-full input ${isInvalid ? 'border-red-500' :''}`}
                name="message"
                id="message"
                cols="30"
                rows="8"
                placeholder={placeholder}
                onChange={onChange}
                onBlur={() => setTouched(true)}
          />
            {
                isInvalid ?
                    (<span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {t(invalidMessagePath)}
		        </span>) : null
            }
        </div>
    )
};

export default TextArea;
