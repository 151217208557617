import * as React from "react";

const Delete = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.395" height="11.395" viewBox="0 0 11.395 11.395">
            <path fill="#FF0000" d="M353 308.289l-3.409 3.409 3.409 3.408a1.341 1.341 0 0 1-1.9 1.9l-3.409-3.409-3.403 3.403a1.341 1.341 0 1 1-1.9-1.9l3.412-3.4-3.409-3.409a1.341 1.341 0 0 1 1.9-1.9l3.409 3.409 3.409-3.409a1.341 1.341 0 0 1 1.9 1.9zm0 0" opacity="0.688" transform="rotate(-90 23.697 329.697)"/>
        </svg>
    );
}

export default Delete;